<template>
  <v-tooltip :text="$t(tooltipKey)">
    <template #activator="{ props: bind }">
      <v-btn
        v-bind="bind"
        icon
        size="x-small"
        variant="outlined"
        :color="color"
        :class="btnClass"
        :disabled="disabled"
        @click="handleClick"
      >
        <v-icon :data-cy="dataCy">{{ icon }}</v-icon>
      </v-btn>
    </template>
  </v-tooltip>
</template>

<script lang="ts" setup>
import { useI18n } from '@/composables/useI18n';
import { useSnack } from '@/composables/useSnack';
import { useLogger } from '@/composables/useLogger';

const { addSnack } = useSnack();
const { logger } = useLogger();
const { t } = useI18n();

/**
 * Asynchronously copies provided HTML and plain text to the clipboard.
 *
 * @param {Object} params - The content to be copied to the clipboard.
 * @param {string} params.html - The HTML content to be copied.
 * @param {string} params.text - The plain text content to be copied.
 * @return {Promise<void>} A promise that resolves when the content is successfully copied.
 */
const copyToClipboard = async ({ html, text }: { html: string; text: string }): Promise<void> => {
  if (!navigator.clipboard || !navigator.clipboard.write) {
    logger.error('Clipboard API is not supported');
    return;
  }

  try {
    const htmlBlob = new Blob([html], { type: 'text/html' });
    const textBlob = new Blob([text], { type: 'text/plain' });

    const clipboardItem = new ClipboardItem({
      'text/html': htmlBlob,
      'text/plain': textBlob,
    });

    await navigator.clipboard.write([clipboardItem]);
    logger.info('HTML and text successfully copied to clipboard');
  } catch (err) {
    logger.error('Can not copy to clipboard: ', err);
  }
};

interface Props {
  getData?: () => { html: string; text: string };
  dataCy?: string;
  tooltipKey?: string;
  snackKey?: string;
  icon?: string;
  color?: string;
  btnClass?: string;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  getData: undefined,
  dataCy: '',
  tooltipKey: 'buttons.copyToClipboard',
  snackKey: 'toast.copiedToClipboard',
  icon: 'mdi-content-copy',
  color: 'green',
  btnClass: '',
  disabled: false,
});

const emit = defineEmits(['click']);

const handleClick = () => {
  emit('click');

  if (!props.getData) {
    return;
  }
  const { html, text } = props.getData();
  copyToClipboard({
    html,
    text,
  });
  addSnack({
    id: props.snackKey,
    message: t(props.snackKey),
    color: 'success',
  });
};
</script>
