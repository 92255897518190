export const useBrowser = () => {
  const isSafari = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;
  };

  return {
    isSafari,
  };
};
