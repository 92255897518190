import { Item } from '@/types/common';

export const useSort = () => {
  const sortAlphabetically = (items: Item[], propertyForSort: CallableFunction | string) => {
    const itemsToSort = [...items];
    return itemsToSort.sort((a: Item, b: Item) => {
      let aName;
      let bName;
      if (a !== Object(a)) {
        aName = a;
        bName = b;
      } else {
        if (typeof propertyForSort === 'function') {
          aName = propertyForSort(a);
          bName = propertyForSort(b);
        } else {
          aName = a[propertyForSort] || a;
          bName = b[propertyForSort] || b;
        }
      }
      aName = aName.toString();
      bName = bName.toString();

      // remove undefined with locale after adding localization to project
      return aName.localeCompare(bName, undefined, { numeric: true, sensitivity: 'base' });
    });
  };

  return {
    sortAlphabetically,
  };
};
