export const useColor = () => {
  /**
   * Generate color for success - error range, input values 0..100
   *
   * @param valInPercent
   * @returns {string}
   */
  const getColorOnColorScale = (valInPercent: number): string => {
    let r = 0;
    let g = 0;
    const b = 50;

    if (valInPercent < 50) {
      r = 255;
      g = Math.round(5.1 * valInPercent);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * valInPercent);
    }

    const h = r * 0x10000 + g * 0x100 + b;
    return '#' + ('000000' + h.toString(16)).slice(-6);
  };

  return {
    getColorOnColorScale,
  };
};
