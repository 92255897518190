import i18n from '@/i18n';

export const useI18n = () => {
  const t = (key: string, value = {}) => {
    return i18n.global.t(key, value);
  };

  const te = (key: string) => {
    return i18n.global.te(key);
  };

  return {
    t,
    te,
  };
};
