<template>
  <v-autocomplete
    v-model="localValue"
    :style="cssVars"
    :data-cy="dataCy"
    :density="density"
    :items="sortedItems"
    :hide-details="hideDetails"
    :disabled="disabled"
    :single-line="singleLine"
    :item-title="itemTitle"
    :item-value="itemValue"
    :menu="menu"
    :multiple="multiple"
    :chips="multiple"
    :closable-chips="closableChips"
    :clearable="clearable"
    :error="error"
    :messages="messages"
  >
    <template #prepend-item> <div :data-cy="dataCy" class="fake-prepend-item"></div></template>
    <template v-for="(_, slot) of $slots as AutocompleteSlots" #[slot]="slotData">
      <slot :name="slot" v-bind="slotData" />
    </template>
  </v-autocomplete>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import type { AutocompleteSlots } from '@/types/vuetify.d';
import type { Density, Item, ModelValue } from '@/types/common.d';
import { useSort } from '@/composables/useSort';

type Props = {
  density?: Density;
  items: Item[];
  itemValue?: string;
  itemTitle?: string | (() => string);
  menu?: boolean;
  error?: boolean;
  messages?: string;
  modelValue?: ModelValue;
  hideDetails?: boolean;
  singleLine?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  chips?: boolean;
  closableChips?: boolean;
  clearable?: boolean;
  dataCy?: string;
  width?: number;
};

const emit = defineEmits(['update:modelValue', 'change']);
const props = withDefaults(defineProps<Props>(), {
  density: 'default',
  itemValue: 'value',
  itemTitle: 'title',
  modelValue: null,
  menu: false,
  multiple: false,
  chips: true,
  closableChips: false,
  clearable: true,
  dataCy: undefined,
  messages: '',
  width: undefined,
});

const { sortAlphabetically } = useSort();

const sortedItems = computed<Item[]>(() => sortAlphabetically(props.items, props.itemTitle));
const localValue = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:modelValue', localValue);
    emit('change', localValue);
  },
});

const cssVars = computed(() => ({
  '--input-width': `${props.width}px`,
}));
</script>
<style lang="scss" scoped>
.v-list-item {
  min-width: 250px;
}
.fake-prepend-item {
  height: 1px;
}
::v-deep(.v-field) {
  width: var(--input-width);
}
</style>
