import { computed } from 'vue';
import { useDisplay as useVuetifyDIsplay } from 'vuetify';

export const useDisplay = () => {
  const { xs, sm, md } = useVuetifyDIsplay();
  const isMobile = computed(() => xs.value || sm.value);
  const isMediumScreen = computed(() => md.value);

  return {
    isMobile,
    isMedium: isMediumScreen,
  };
};
