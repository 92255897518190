<template>
  <c-select
    v-model="selected"
    data-cy="collaborator-selector"
    density="comfortable"
    label="Collaborator"
    :items="collaboratorProfileItems"
    :item-title="getCollaboratorFullName"
    item-value="uuid"
    :clearable="false"
    :menu-props="{ style: 'z-index: 1;' }"
    @update:model-value="onCollaboratorProfileSelect"
  ></c-select>
  <input
    id="select-input-proxy"
    :value="collaboratorProfileItems.map(item => item.collaborator.id).join(', ')"
    type="hidden"
  />
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, watchEffect } from 'vue';
import { getCollaboratorFullName } from './utils';
import { CollaboratorProfileOutputDto } from 'generated-api/erp';
import CSelect from '@/components/Common/CSelect.vue';

const emit = defineEmits<{
  (e: 'selectProfile', profile: CollaboratorProfileOutputDto): void;
}>();

const props = defineProps<{
  collaboratorProfiles: CollaboratorProfileOutputDto[];
  selectedCollaboratorUuid?: string;
}>();

const collaboratorProfileItems = ref<CollaboratorProfileOutputDto[]>([]);
const selected = ref<string | null>(null);

let preventEmit = true;

const onCollaboratorProfileSelect = (uuid: string) => {
  const selectedProfile = props.collaboratorProfiles.find(profile => profile.uuid === uuid);
  if (selectedProfile) {
    emit('selectProfile', selectedProfile);
  }
};

onMounted(() => {
  collaboratorProfileItems.value = [...props.collaboratorProfiles];

  if (props.selectedCollaboratorUuid) {
    selected.value = props.selectedCollaboratorUuid;
  }

  preventEmit = false;
});

watch(
  () => props.collaboratorProfiles,
  newProfiles => {
    collaboratorProfileItems.value = [...newProfiles];
  },
);

watch(
  () => props.selectedCollaboratorUuid,
  newUuid => {
    if (newUuid) {
      selected.value = newUuid;
    }
  },
);

// Handle reactivity for the selected value
watchEffect(() => {
  if (!preventEmit && selected.value) {
    onCollaboratorProfileSelect(selected.value);
  }
});
</script>

<style lang="scss" scoped></style>
